.userAssessmentInput {
  display: block;
}

.userAssessmentInput > * {
  margin-bottom: 6px;
}

.background {
  background-color: #f5f3ed;
  background-image: url(src/assets/image/pattern.png);
  height: 100vh;
}

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  h1 {
    font-size: 1rem;
    font-weight: bold;
  }

  :global(.ant-radio-wrapper) {
    background: none;
    text-transform: none;
  }
  
  :global(.ant-row.ant-form-item:first-child) {
    margin-bottom: 0;
  }
  
  :global(.ant-radio-group.ant-radio-group-outline) {
    padding-top: 2rem;
  }
}

.formContainer {
  min-height: 450px;
  width: 55%;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 992px) {
  .formContainer {
    width: 85%;
    min-height: 550px;
  }
}
